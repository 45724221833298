const confidentialityPopup = document.getElementById('confidentiality');
const confidentialityContent = document.querySelector('.popup__confidentiality');
const confidentialityClose = document.querySelector('.popup__confidentiality-close');
// const body = document.querySelector('body');


 function closeConfidentialityPopup() {
   confidentialityContent.classList.remove('open');
   body.classList.remove('modal-open');
 }

 confidentialityPopup.addEventListener('click', () => {
   confidentialityContent.classList.toggle('open');
   body.classList.add('modal-open');
 });

 confidentialityClose.addEventListener('click', () => {
   confidentialityContent.classList.remove('open');
   body.classList.remove('modal-open');
 });