// слайдер plot___________________________________________________

const swiperPlot = new Swiper(".swiper__plot", {
   slidesPerView: 1,
   // spaceBetween: 10,
   navigation: {
     nextEl: ".plot-button-next",
     prevEl: ".plot-button-prev",
   },
   breakpoints: {
     640: {
       slidesPerView: 2,
     },
     768: {
       slidesPerView: 2,
     },
     1024: {
       slidesPerView: 3,
     },
 
     1366: {
       slidesPerView: 4,
     },
   },
 });
 
 
 
 // слайдер  emotions
 
 const swiperEmotions = new Swiper(".swiper__emotions", {
   slidesPerView: 1,
   // spaceBetween: 10,
   navigation: {
     nextEl: ".swiper-button-next",
     prevEl: ".swiper-button-prev",
   },
   breakpoints: {
     640: {
       slidesPerView: 2,
     },
     1024: {
       slidesPerView: 2,
     },
 
     1366: {
       slidesPerView: 4,
     },
   },
 });
 
 // слайдер reviews____________________________________
 
 const swiperReviews = new Swiper(".swiper__reviews", {
   slidesPerView: 1,
   effect: "fade",
   navigation: {
     nextEl: ".reviews-button-next",
     prevEl: ".reviews-button-prev",
   },
   breakpoints: {
     640: {
       slidesPerView: 1,
     },
   },
 });