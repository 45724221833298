// animate________________
const ray = document.querySelectorAll(".animate");
function animateRays() {
  ray.forEach((el) => el.classList.add("animate__flash"));
  setTimeout(function () {
    ray.forEach((el) => el.classList.remove("animate__flash"));
  }, 1000)
}
setInterval(animateRays, 5000);


function animate(element) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const currentElement = element.querySelector("[data-visible]");
      const nextElement =
        currentElement.nextElementSibling || element.firstElementChild;
      currentElement.removeAttribute("data-visible");
      nextElement.setAttribute("data-visible", true);
      resolve();
    }, 300);
  });
}

async function startAnimation(container) {
  container.style.display = "block";

  const count = container.childElementCount - 1;
  Array.from(container.children).forEach((child) =>
    child.removeAttribute("data-visible")
  );
  container.firstElementChild.setAttribute("data-visible", true);

  for (let i = 0; i < count; i++) {
    await animate(container);
  }

  setTimeout(() => {
    container.style.display = "none";
  }, 300);
}

const flowerContainer = document.querySelector(".flower-animate");
setInterval(() => startAnimation(flowerContainer), 7000);


const starContainer = document.querySelector(".star-animate");
setTimeout(() => {
  setInterval(() => startAnimation(starContainer), 7000);
}, 4200);



const wavyContainer = document.querySelector(".wavy-animate");
setTimeout(() => {
  setInterval(() => startAnimation(wavyContainer), 7000);
}, 4200);

const magicContainer = document.querySelector(".magic-animate");
setInterval(() => startAnimation(magicContainer), 7000);

const crownContainer = document.querySelector(".crown-animate");
setInterval(() => startAnimation(crownContainer), 7000);
