// бургер меню
const iconMenu = document.querySelectorAll('.menu__icon');
const burgerMenu = document.querySelectorAll('.burger');

const burgerLink = document.querySelectorAll('.burger__link');



iconMenu.forEach((icon) => {
  icon.addEventListener('click', function () {
    icon.classList.toggle('_active');
    burgerMenu.forEach((menu) => {
      menu.classList.toggle('_active');
      if (menu.classList.contains('_active')) {
        body.classList.add('modal-open');
      } else {
        body.classList.remove('modal-open');
      }
    })
  })
})


burgerLink.forEach(function (el) {
  el.addEventListener("click", function () {
    closeBurgerMenu();
    // header.classList.remove('header-block');
    // body.classList.remove('modal-open');
  })
})


function closeBurgerMenu() {
  iconMenu.forEach((icon) => {
    icon.classList.remove('_active');
    burgerMenu.forEach((menu) => {
      menu.classList.remove('_active');
    })
    body.classList.remove('modal-open');
  })
}