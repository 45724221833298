// parallax____________________

const section = document.querySelector("#parallax");

const observer = new IntersectionObserver(handleIntersection, {
  threshold: 0.5,
});

observer.observe(section);

function handleIntersection(entries) {
  const [entry] = entries;

  if (entry.isIntersecting) {
    document.addEventListener("scroll", handleScroll, false);
  } else {
    document.removeEventListener("scroll", handleScroll, false);
  }
}

let lastScrollTop = 0;

function handleScroll() {
  const st = window.pageYOffset || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    // downscroll code
    section.classList.add("animate");
  }

  if (st < lastScrollTop) {
    // upscroll code
    section.classList.remove("animate");
  }

  lastScrollTop = st <= 0 ? 0 : st;
}

