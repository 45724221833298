// popup выбор города

const selectCity = document.querySelectorAll('.header__select-sity');
const popupCity = document.querySelector('#city-selector');
const buttonClose = document.querySelector('.popup__close');
const popupBody = document.querySelector('.popup__body');
const currentCity = document.querySelector('.city__row-current');

// window.addEventListener('load', openSelectSity);

function openSelectSity() {
  closeBurgerMenu();
  popupCity.classList.toggle('open');
  body.classList.add('modal-open');
}

function closeSelectSity() {
  popupCity.classList.remove('open');
  body.classList.remove('modal-open');
}

selectCity.forEach((elem) => {
  elem.addEventListener('click', openSelectSity)
})

buttonClose.addEventListener('click', (e) => {
  e.preventDefault();
  closeSelectSity()
})

currentCity.addEventListener('click', (e) => {
  closeSelectSity();
})

popupBody.addEventListener('click', (event) => {
  if (event.target !== popupBody) {
    return
  }
  closeSelectSity();
})

// Окно бронирования 

const btnBuy = document.querySelectorAll('.btn-buy');
const popupReservation = document.querySelector('#popup-reservation');
const popupReservationBody = document.querySelector('.popup__reservation-body');
const buttonCloseReservation = document.querySelector('.popup__close-reservation');

function openReservation() {
  popupReservation.classList.toggle('open');
  body.classList.add('modal-open');
}

function closeReservation() {
  popupReservation.classList.remove('open');
  body.classList.remove('modal-open');
}

btnBuy.forEach((elem) => {
  elem.addEventListener('click', openReservation)
})

buttonCloseReservation.addEventListener('click', (e) => {
  e.preventDefault();
  closeReservation();
})

popupReservationBody.addEventListener('click', (event) => {
  if (event.target !== popupReservationBody) {
    return
  }
  closeReservation();
})

const element = document.querySelector('.popup-reservation__inp-phone'); 
const mask = new IMask(element, {
  mask: '+7(000)000-00-00',
});