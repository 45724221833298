const certificatePopupBtn = document.querySelectorAll('.btn-buy-certificate');
const certificateContent = document.querySelector('.popup__certificate');
const certificateClose = document.querySelector('.popup__certificate-close');


certificatePopupBtn.forEach((btn) => {
   btn.addEventListener('click',() => {
      certificateContent.classList.toggle('open');
      body.classList.add('modal-open');
   })
})

function closeCertificatePopup() {
   certificateContent.classList.remove('open');
   body.classList.remove('modal-open');
 }


 certificateClose.addEventListener('click', () => {
   certificateContent.classList.remove('open');
   body.classList.remove('modal-open');
 });