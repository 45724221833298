const header = document.querySelector('.header');
let lastScroll = 0;

window.addEventListener('scroll', () => {
  const scrollDistance = window.scrollY;

  if (scrollDistance < lastScroll && scrollDistance > 700) {
    header.classList.add('header-block');
  } else {
    header.classList.remove('header-block');
  }

  lastScroll = scrollDistance;
})

// _____плавный скролл_______________ 
const smoothLinks = document.querySelectorAll('a[href^="#"]');
for (let smoothLink of smoothLinks) {
  smoothLink.addEventListener('click', function (e) {
    e.preventDefault();
    const id = smoothLink.getAttribute('href');

    document.querySelector(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  });
};

// ____карты_________
const btn = document.getElementById('btn');
const eldarCoord = [55.665309, 37.513395];// эльдар
const ggelCoord = [55.829679, 37.453926];// гжель

function initYandexMap(id, center) {
  const map = new ymaps.Map(id, {
    center,
    zoom: 16,
    controls: [],
  });
  const ggelPlacemark = new ymaps.Placemark(
    ggelCoord,
  );
  const eldarPlacemark = new ymaps.Placemark(
    eldarCoord,
  );

  map.behaviors.disable(['scrollZoom']);
  map.geoObjects.add(ggelPlacemark);
  map.geoObjects.add(eldarPlacemark);
}




function initFooter() {
  const zoom = 15
  const myMapFooter = new ymaps.Map("footer-map", {
    center: eldarCoord,
    zoom: zoom,
    controls: [],
  });
  const myPlacemark = new ymaps.Placemark(
    eldarCoord,
  );
  const myPlacemark2 = new ymaps.Placemark(
    ggelCoord,
  );

  myMapFooter.behaviors.disable(['scrollZoom']);

  myMapFooter.geoObjects.add(myPlacemark);
  myMapFooter.geoObjects.add(myPlacemark2);

  // prevButton.addEventListener('click', handleChangeAddress)
  // nextButton.addEventListener('click', handleChangeAddress)

  // function handleChangeAddress() {
  //   if (slides[slideIndex] === eldarAddress) {
  //     myMapFooter.setCenter(eldarCoord, zoom, {
  //       checkZoomRange: true
  //     });
  //   } else {
  //     myMapFooter.setCenter(ggelCoord, zoom, {
  //       checkZoomRange: true
  //     });
  //   }
  // }
}

ymaps.ready(initFooter);


// карточки_____________________________________________________________

const body = document.querySelector('body');
const tabsBtn = document.querySelectorAll(".tabs-btn");
const contentItems = document.querySelectorAll(".schedule__main-card");
const buttonList = document.querySelector(".schedule__small-cards");
const overlay = document.querySelector(".overlay");
const scheduleBtn = document.querySelectorAll('.schedule__button');


// TODO: проверить как будет работать на desktop & mobile


window.addEventListener("resize", handleShowClick);
handleShowClick();

if (window.innerWidth > 1024) {
  ymaps.ready(runYandexMap);
}

function closeMainCard() {
  overlay.classList.remove("visible");
  overlay.innerHTML = "";
  body.classList.remove('modal-open');
}

overlay.addEventListener("click", (event) => {
  if (event.target !== overlay) {
    return
  }
  closeMainCard()
});



function handleShowClick() {
  buttonList.querySelectorAll(".tabs-btn").forEach((button) => {
    if (window.innerWidth <= 1024) {
      button.removeEventListener("click", handleShowClickDesktop);
      button.addEventListener("click", handleShowClickMobile);
    } else {
      button.removeEventListener("click", handleShowClickMobile);
      button.addEventListener("click", handleShowClickDesktop);
    }
  });
}

function handleShowClickMobile(event) {
  const id = event.currentTarget.dataset.showid;
  const coord = JSON.parse(event.currentTarget.dataset.coord);
  const showContent = document.querySelector(`[data-id="${id}"]`);
  const cardClone = showContent.cloneNode(true)
  const mapContainer = cardClone.querySelector('.schedule__map')
  const closeBtn = cardClone.querySelector('.card-close');
  // const cheduleBtn = cardClone.querySelector('.schedule__button');
   
  closeBtn.addEventListener('click', () => {
    closeMainCard()
  })

  // cheduleBtn.addEventListener('click', () => {
  //   closeMainCard()
  // })

  overlay.appendChild(cardClone);
  overlay.classList.add("visible");
  // body.classList.add('modal-open');
  initYandexMap(mapContainer, coord)
}


function handleShowClickDesktop(event) {
  tabsBtn.forEach((item) => {
    item.classList.remove('active');
  })
  event.currentTarget.classList.add("active");
  contentItems.forEach((item) => item.classList.remove("active"));
  runYandexMap()
}


function runYandexMap() {
  const activeButton = Array.from(tabsBtn).find((button) => button.classList.contains('active'))
  const id = activeButton.dataset.showid;
  const coord = JSON.parse(activeButton.dataset.coord);
  const showContent = document.querySelector(`[data-id="${id}"]`);
  const mapContainer = showContent.querySelector('.schedule__map')

  showContent.classList.add("active");
  initYandexMap(mapContainer, coord)
}


const emotionsVideo = document.querySelectorAll(".my-video"); 

emotionsVideo.forEach((vid) => {
vid.addEventListener('mouseenter', function() {
  vid.play();
});

vid.addEventListener('mouseleave', function() {
  vid.pause();
});
})


const handleSwitchedToNewRenderTarget = console.log
// const closeConfirmation = document.querySelector('.confirmation-btn__close');

// closeConfirmation.addEventListener('click', () => {
//     console.log("object");
//   })


const form = document.querySelector('.form-send');

form.addEventListener('submit', (event) => {
  event.preventDefault(); // Prevent the default form submission
  const data = new FormData(event.target);

  // Sending the POST request using Fetch API
  fetch('/mail.php', {
      method: 'POST',
      body: data,
  })
  closeReservation();
event.target.reset()

});