// аккордеон
const accordionButtonList = document.querySelectorAll(".faq__accordion");

accordionButtonList.forEach(function (currentButton) {
  currentButton.addEventListener("click", () => {
    currentButton.classList.toggle("_active");
    const currentList = currentButton.nextElementSibling;
    const isCurrentListVisible = Boolean(currentList.style.maxHeight)

    if (isCurrentListVisible) {
      document.querySelectorAll('.faq__panel').forEach((list) => list.style.maxHeight = null)
    } else {
      document.querySelectorAll('.faq__panel').forEach((list) => {
        list.style.maxHeight = null
        const button = list.previousElementSibling
        if (list !== currentList) {
          button.classList.remove('_active')
        }
      })
      currentList.style.maxHeight = currentList.scrollHeight + 'px'
    }
  })
})